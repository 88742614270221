//CustomLoader

import React, {useEffect} from 'react';
import {Select} from "antd";
import styled from "styled-components";


export default function CustomLoader({ id, isActive, isWhiteCircle = true, isBlackCircle = false, type = 'button' }) {
  return (
    <>
      <CustomLoaderWrapper className={`lk__loader-container lk-${type}-loader`} style={{ display: isActive ? 'flex' : 'none' }}>
        <svg className="lk-loader-content" viewBox="0 0 26 26">
          <circle className={`lk-loader-circle${!isWhiteCircle ? '' : ' white-circle'}${!isBlackCircle ? '' : ' black-circle'}`} cx="13" cy="13" r="11" fill="none" strokeWidth="1.6"/>
        </svg>
      </CustomLoaderWrapper>
    </>
  )
}

export const CustomLoaderWrapper = styled.div`
  &.lk__loader-container {
    position: relative;
    top: 2px;
    height: 20px;
    display: none;

    &.lk-light-loader {
      top: 18px;
      position: absolute;
      right: -38px;
    }
    
    &.lk-show-loader {
      display: block;
    }
    
    &.lk-button-loader {
      top: -1px;
      min-height: 24px;
    }
    
    &.lk-checkbox-loader {
      margin: auto 8px;
      padding-top: 6px;
      top: -3px;
    }
    
    &.lk-checkbox-loader.mailing-loader {
      position: absolute;
      width: auto;
      right: -40px;
      top: 14px;
      margin: 0;
      
      &.good-vacancies-loader {
        justify-content: end;
      }
    }

    .lk-loader-content {
      width: 20px;
      height: 20px;
      animation: svgSpinner 1.4s linear infinite;
      margin-right: 12px;
    }
    
    &.lk-button-loader  {
      .lk-loader-content {
        width: 25px;
        height: 25px;
      }
    }

    .lk-loader-circle {
      animation: svgSpinnerCircle 1.4s ease-in-out infinite;
      stroke-dasharray: 80px, 200px;
      stroke-dashoffset: 0px;
      stroke: #EF6957;
      stroke-linecap: round;
    }

    .white-circle {
      stroke: #F9F9F9;
    }

    .black-circle {
      stroke: #323232;
    }
  }

  @keyframes svgSpinner {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes svgSpinnerCircle {
    0% {
      stroke-dasharray: 1px, 200px;
      stroke-dashoffset: 0px;
    }
    50% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -15px;
    }
    100% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -120px;
    }
  }

  @media (max-width: 990px) {
    &.lk__loader-container {
      top: -1px;
      
      &.lk-checkbox-loader {
        top: -3px;
      }

      &.lk-light-loader {
        top: 12px;
      }
    }
  }

  @media (max-width: 660px) {
    &.lk__loader-container {
      &.lk-light-loader {
        position: relative;
        top: 0;
        right: -6px;
      }
    }
  }

  @media (max-width: 575px) {
    .lk__loader-container {
      top: -5px;
      
      &.lk-checkbox-loader {
        top: 4px;
      }
      
      &.lk-checkbox-loader.mailing-loader {
        padding-top: 5px;
      }
    }
  }

`