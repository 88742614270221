export const theme = {
  colors: {
    white: '#FFFFFF',
    gray: '#F5F7FB',
    orange: '#EF6957',
    hoverOrange: '#FF6E5A',
    hoverPickerOrange: '#FF6E5AC7',
    translucentOrange: '#EF735775',
    shadowOrange: '#ef735712',
    lightGray: '#F9F9F9',
    darkGray: '#8A8A89',
    black: '#323232',
  },
  animation: {
    transition: '.25s ease-in-out',
    transitionFast: '.15s',
  }
}