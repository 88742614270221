import {GlobalStyle} from "../styles/GlobalStyle";
import 'swiper/swiper.scss';
import 'swiper/components/effect-coverflow/effect-coverflow.scss';
import 'swiper/components/navigation/navigation.scss';
import 'antd/dist/antd.css';
import "aos/dist/aos.css";
import '../styles/fonts.css';
import '../styles/styles.css';
import '../styles/custom.css';
import '../styles/vacancy.css';
import '../styles/old-styles.css';
//import '../styles/libs.min.css';
//import '../styles/animate.min.css';
import React from "react";
import { useRouter } from 'next/router';
import {ORDER_ID, PRICE_AMOUNT, VL_PR} from "../helpers/common";
import {destroyCookie, parseCookies, setCookie} from "nookies";
import gtm from "../helpers/gtm";
import ru_RU from 'antd/lib/locale/ru_RU';
import 'moment/locale/ru';
import {ConfigProvider} from "antd";
import queryString from "query-string";


function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const cookies = parseCookies();
  const { vl_token, vl_o, price, user } = cookies;

  // useEffect(() => {
  //   const handleRouteChange = (url) => {
  //     gtag.pageview(url);
  //     ym('hit', url);
  //   }
  //   if (typeof window !== "undefined") {
  //     handleRouteChange(window.location.pathname)
  //   }
  // }, [router.events]);


  if (process.browser) {
    const user_id = user ? JSON.parse(user)?.id : null;
    if (vl_o && price && vl_token) {
      if (gtm.isGtmLoaded()) {
        gtm.pushPurchase(vl_o, price);
        destroyCookie(null, ORDER_ID);
        destroyCookie(null, PRICE_AMOUNT);
        router.replace(window.location.pathname, undefined, { shallow: true });
      }
    }
    //vluid=37&token=1189f642aff783996b5d28ead95bf00e
    if (router.query?.vluid || router.query?.token) {
      const query = router.query;
      if (query['vluid'] || query['token']) {
        delete query['vluid'];
        delete query['token'];
      }
      router.replace(queryString.stringifyUrl({url: window.location.pathname, query}), undefined, { shallow: true })
    }

    if (user_id) {
      window.dataLayer?.push({
        vluid: parseInt(user_id)
      });
    }
    setCookie(null, VL_PR, window.document.referrer);
  }

  //       <Script
  //         strategy="afterInteractive"
  //         src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
  //       />
  //       <Script
  //         strategy="afterInteractive"
  //         dangerouslySetInnerHTML={{
  //           __html: `
  //             window.dataLayer = window.dataLayer || [];
  //             function gtag(){dataLayer.push(arguments);}
  //             gtag('js', new Date());
  //             gtag('config', '${gtag.GA_TRACKING_ID}', {
  //               page_path: window.location.pathname,
  //             });
  //           `,
  //         }}
  //       />
  //       <YMInitializer accounts={[parseInt(YANDEX_TRACKING_ID)]} options={ {webvisor: true} } version="2" />

  return (
    <>
      <GlobalStyle />
      <ConfigProvider locale={ru_RU}>
        <Component {...pageProps} />
      </ConfigProvider>
    </>
  )
}

export default MyApp
