export default {
  isGtmLoaded: () => {
    return !!(
      window.dataLayer
      && typeof window.dataLayer.push === 'function'
      && window.google_tag_manager
    );
  },
  pushToDataLayer: data => {
    window.dataLayer.push(data)
  },
  pushToEmail: (email, form_code) => {
    window.dataLayer.push({
      'userEmail': email
    });
    window.dataLayer.push({
      'event': 'data_to_gtm',
      'data_category': 'Subscription',
      'data_action': 'submit',
      'data_label': form_code,
      'data_content_name': ''
    });
  },
  pushPurchase: (id, price) => {
    window.dataLayer.push({
      'event': 'purchase',
      'ecommerce': {
        'purchase': {
          'actionField': {
            'id': id,
            'revenue': price.toString(),
          },
          'products': [{
            'name': 'Пополнение лицевого счёта',
            'id': 'vacancies-subscription-refill',
            'price': price.toString(),
            'quantity': 1
          }]
        }
      }
    });
  },
  pushBeforeBeforePurchase: (price) => {
    window.dataLayer.push({
      'event': 'detail',
      'ecommerce': {
        'detail': {
          'actionField': {},
          'products': [{
            'name': 'Пополнение лицевого счёта',
            'id': 'vacancies-subscription-refill',
            'price': price.toString(),
            'quantity': 1
          }]
        }
      }
    });
  },
  pushAddToCart: (email, price, paymentUrl) => {
    window.dataLayer.push({
      'userEmail': email
    });

    window.dataLayer.push({
      'event': 'addToCart',
      'ecommerce': {
        'currencyCode': 'RUB',
        'add': {
          'products': [{
            'name': 'Пополнение лицевого счёта',
            'id': 'vacancies-subscription-refill',
            'price': price.toString(),
            'quantity': 1
          }]
        }
      }
    });

    window.dataLayer.push({
      'event': 'checkout',
      'ecommerce': {
        'checkout': {
          'actionField': {},
          'products': [{
            'name': 'Пополнение лицевого счёта',
            'id': 'vacancies-subscription-refill',
            'price': price.toString(),
            'quantity': 1
          }]
        }
      },
      'eventCallback': function () {
        document.location = paymentUrl;
      }
    });
  }
}