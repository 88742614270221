export const rules = [{
  required: true,
  message: '',
}]

export const SUCCESS = 'success';

export const ERROR = 'error';

export const REF_TYPE = {
  POSITION: 'POSITION',
  INDUSTRY: 'INDUSTRY',
  SUBINDUSTRY: 'SUBINDUSTRY',
  COMPANY_LEVEL: 'COMPANY_LEVEL',
  FUNCTION: 'FUNCTION',
  SUBFUNCTION: 'SUBFUNCTION',
}

export const NEW_POSITION = {
  company_level: null,
  function: null,
  subfunction: null,
  id: null,
  industry: null,
  subindustry: null,
  level: null,
  user_id: null,
  salary: null,
  range_date: [null, null], // "2021-11-01" | "present" | null
  order_index: null,
  title: null
}

export const POSITION_DATE_FORMAT = 'YYYY-MM'

export const BLOCK_POSITION = {
  TOP: 'main-mailing__form-right posit-triag',
  TOP_BOTTOM: 'main-mailing__form-right',
  BOTTOM: 'main-mailing__form-right posit-prev posit-triag',
  BOTTOM_BOTTOM: 'main-mailing__form-right posit-prev'
}

export const MAIN_PAGE = '/vacancies-subscription';

export const MAIN_PAGE_SMALL = '/vacancies-subscription-small';

export const LOGOUT_PAGE = '/logout';

export const REDIRECT_TO_MAIN_PAGE = {
  redirect: {
    destination: MAIN_PAGE,
    permanent: false,
  },
}

// export const REDIRECT_TO_LOGOUT = {
//   redirect: {
//     destination: LOGOUT_PAGE,
//     permanent: false,
//   },
// }

export const cookieMaxAge = 3600 * 1000 * 24 * 365 * 10;

export const USER = 'user';

export const TOKEN = 'vl_token';

export const REFERRER_ID = 'referrer_id';

export const ORDER_ID = 'vl_o';

export const PRICE_AMOUNT = 'price';

export const REFERRAL_ID = 'referral_id';

export const SHARE_ID = 'share_id';

export const VL_PR = 'vl_rp';

export const SETTINGS = 'settings';

export const PERSONIFIED_BLOCK = 'vl_vacancy_block';

export const YANDEX_TRACKING_ID = process.env.NEXT_PUBLIC_YANDEX_ID || null;

export const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID || null;

export const PROXY_PREFIX = process.env.NEXT_PUBLIC_PROXY_PREFIX || '/api/v1';
export const NEW_PROXY_PREFIX = process.env.NEXT_PUBLIC_NEW_PROXY_PREFIX || '/api/v2';
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
export const API_URL = BASE_URL || process.env.NEXT_PUBLIC_API_BASE_URL || 'https://127.0.0.1:8080';

export const INDEX_BY_ROBOTS = process.env.NEXT_PUBLIC_INDEX_BY_ROBOTS !== undefined ? process.env.NEXT_PUBLIC_INDEX_BY_ROBOTS : true;

export const DATE_FORMAT = 'D MMMM YYYY';

export const DATETIME_FORMAT = 'DD MMMM YYYY HH:mm:ss';

export const SERVER_DATE_FORMAT = 'D/M/YYYY';

export const DEFAULT_PLACEHOLDER_VALUE = 'Выберите из списка';

export const CORRECT_FILE_EXTS = [
  '.doc',
  '.docx',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'image/jpeg',
  'image/gif',
  'image/png',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'text/plain',
]

export const ERROR_CODES = {
  'invalid_data': 1003,

  'email_exists': 1030,
  'phone_exists': 1031,
  'verification_email': 1035,
  'auth_email_not_exists': 1036,

  'not_enough_money': 1060,
  'not_send_message': 1084,

  'error': 500
}

export const FORM_TYPES = {
  SUBSCRIPTION: 'subscription',
  SUBSCRIPTION_QUESTION: 'subscription_question',
  VACANCY_QUESTION: 'vacancy_question'
}

export const FORM_EVENT_TYPE = {
  START_FILLING_FORM: 'start_filling_form',
  FILLING_FORM: 'filling_form',
}

export const EXPERTS = [
  {
    name: 'Анна Алфимова',
    image_url: '/images/experts/anna.png',
    positions: [
      'Член NCDA — Национальной ассоциации карьерного развития.',
      'Более 8 лет занимается развитием персонала и карьерным продвижением.',
      'Эксперт по стратегиям карьерных переходов и потребностей бизнеса в кадрах.',
      'Работает с российскими и зарубежными корпорациями и стартапами, коммерческими компаниями, НКО.',
    ]
  },
  {
    name: 'Дмитрий Утукин',
    image_url: '/images/experts/dmitriy.png',
    positions: [
      'Более 10 лет работы в юридическом консалтинге и HR.',
      'Эксперт в расследованиях по открытым источникам',
      'Специалист по выстраиванию коммуникации и получению информации для оценки репутации',
      'Нацелен на то, чтобы вы получили выгодное предложение о работе',
    ]
  },
  {
    name: 'Евгения Галактионова',
    image_url: '/images/experts/evgenia.png',
    positions: [
      '10 лет работы по подбору персонала для крупных компаний и малого бизнеса',
      '4 года является специалистом карьерного консультирования и профориентации.',
      'Более 500 успешных кейсов: от смены должности и повышения до перехода в другую отрасль.',
      'Работает с построением карьерного трека и его воплощением, а также целеполаганием.',
    ]
  },
]

export const BANNER_MAIN_PAGE_TYPE = 'main';
export const BANNER_SMALL_PAGE_TYPE = 'small';
export const BANNER_LIGHT_PAGE_TYPE = 'small';
export const BANNER_MAIN_COOL_PAGE_TYPE = 'cool';
export const BANNER_MAIN_SALARIES_PAGE_TYPE = 'salaries';

export const BANNER_TEXTS = {
  [BANNER_MAIN_PAGE_TYPE]: {
    h1: 'Сервис для <br />',
    orange: 'хороших наёмных <br />менеджеров',
    description: '<i>“Хороший наёмный менеджер начинает искать новую работу в первый день новой работы”</i> <br />…из Кодекса хорошего наёмного менеджера',
    button: 'Подписаться',
  },
  [BANNER_SMALL_PAGE_TYPE]: {
    h1: 'Сервис для <br />',
    orange: 'хороших наёмных <br />менеджеров',
    description: '<i>“Хороший наёмный менеджер начинает искать новую работу в первый день новой работы”</i> <br />…из Кодекса хорошего наёмного менеджера',
    button: 'Подписаться',
  },
  [BANNER_MAIN_COOL_PAGE_TYPE]: {
    h1: 'Крутые вакансии не размещают <br />',
    orange: 'на популярных <br />job сайтах!',
    description: 'Мы находим их по 182 источникам и присылаем вам, указав у каждой уровень дохода',
    button: 'Подписаться',
  },
  [BANNER_MAIN_SALARIES_PAGE_TYPE]: {
    h1: 'Мы знаем <br />',
    orange: 'все зарплаты!',
    description: 'Здесь у каждой вакансии <b>обязательно</b> указан уровень дохода',
    button: 'Подписаться',
  },
}