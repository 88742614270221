import styled, {createGlobalStyle} from "styled-components";
import {theme} from "/helpers/theme";
import {CustomLoaderWrapper} from "../components/Utils/CustomLoader";

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Montserrat', sans-serif;
    outline: none;
  }
  
  h1, h2, h3, h4, h5, h6, p {
    color: #323232;
    margin: 0;
  }
  
  a {
    color: ${theme.colors.black};
    
    .btn:hover {
      color: ${theme.colors.white};
    }
  }
  
  .footer__top-left a:hover, .link-arrow:hover {
    color: ${theme.colors.black};
  }
  
  p {
    //font-style: normal;
    //font-weight: 400;
    //font-size: 16px;
    //line-height: 22px;
  }
  
  .orange-color {
    color: ${theme.colors.orange} 
  }
  
  .animationDelay1 {
    animation-delay: 100ms !important;
  }
  
  .animationDelay2 {
    animation-delay: 200ms !important;
  }
  
  .animationDelay3 {
    animation-delay: 300ms !important;
  }

  .ant-modal-mask {
    background-color: rgba(50, 50, 50, 0.1);
    backdrop-filter: blur(10px);
  }

  .ant-modal-content {
    box-shadow: none;
  }

  .ant-input:placeholder-shown {
    //min-width: 100%;
  }
  
  .ant-btn-primary:hover, .ant-btn-primary:focus {
    background: #FF6E5A !important;
    border-color: #FF6E5A !important;
  }
  
  .ant-btn-primary:disabled {
    &:hover {
      background: #F9F9F9 !important;
      color: #8A8A89 !important;
    }
  }
  
  
  //
  //.btn2:active .black-circle,
  //.btn2:hover .black-circle {
  //  stroke: #EF6957;
  //}
  //
  //.btn2[disabled]:active .black-circle,
  //.btn2[disabled]:hover .black-circle {
  //  stroke: #323232;
  //}

  .btn2 {
    &:hover ${CustomLoaderWrapper}, 
    &:active ${CustomLoaderWrapper} {
      &.lk__loader-container {
        .lk-loader-circle {
          &.black-circle {
            stroke: #EF6957;
          }
        }
      }
    }
    
    &[disabled]:hover ${CustomLoaderWrapper},
    &[disabled]:active ${CustomLoaderWrapper} {
      &.lk__loader-container {
          .lk-loader-circle {
              &.black-circle {
                  stroke: #323232;
              }
          }
      }
    }
  }
  
  .input-full-width {
    max-width: 100% !important;
  }
  
  .ant-upload-list-item-error {
    display: none !important;
  }
  
  .ant-col {
    padding: 0 !important;
  }

  .ant-form-item-label {
    display: block;
    line-height: 19px;
  }
  
  .ant-descriptions-item-label,
  .ant-descriptions-item-content,
  .ant-form-item-label label {
    margin-bottom: 5px!important;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
  
  label:before {
    content: '' !important;
    margin: 0 !important;
  }
  
  input, textarea, .ant-select-selector {
    border: 1px solid ${ ({ isSmall }) => isSmall ? theme.colors.white : '#8A8A89' } !important;
    color: ${theme.colors.black} !important;
    font-family: Montserrat,sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    padding-left: 30px !important;
    padding-right: 15px !important;
    transition: ${theme.animation.transitionFast};
    position: relative;
    z-index: 1 !important;
    transition-property: border !important;
    box-sizing: border-box !important;
    width: 100% !important;
    //max-width: 580px !important;
    
    border-radius: 0 !important;
  }

  input, textarea, .ant-select-selector {
    margin-top: 9px !important;
  }

  .ant-select-item:hover {
    color: ${theme.colors.hoverOrange};
    background: ${theme.colors.shadowOrange};
    font-weight: 600;
  }
  
  .ant-picker-input input, 
  .ant-input-number-input-wrap input {
    margin-top: 0 !important;
  }

  input:not(.ant-radio-input), .ant-select-selector {
    height: 64px !important;
  }

  .ant-select-selection-placeholder, .ant-select-selection-item {
    padding-top: 16px !important;
    font-size: 18px !important;
    font-weight: 500;
  }

  .ant-select-selection-placeholder {
    color: ${theme.colors.darkGray} !important;
  }
  
  input::placeholder, textarea::placeholder, .ant-select-selector::placeholder {
    color: #8A8A89 !important;
    font-weight: 400;
  }
  textarea.ant-input {
    resize: none;
  }
  textarea.ant-input {
    padding-top: 20px;
    padding-bottom: 10px;
    //height: auto !important;
  }
  
  .ant-input:focus {
    box-shadow: none;
  }
  
  .ant-select-selection-item {
    color: ${theme.colors.black} !important;
  }
  
  .ant-select-selector {
    box-shadow: none !important;
  }
  
  input:focus, .ant-select-selector:active {
    box-shadow: none !important;
  }
  
  .ant-btn-primary {
    transition: ${theme.animation.transition};
    font-style: normal;
    font-weight: 600;
    background: ${theme.colors.orange};
    border: none;
    color: ${theme.colors.lightGray};
    cursor: pointer;
    max-width: 238px;
    width: 100%;
    min-width: 151px;
    height: 64px;
    font-size: 20px;
    line-height: 24px;
    padding: 10px 20px 9px;
    padding-left: 35px;
    
    img {
      margin-left: 14px;
      margin-top: 1px;
    }
    
    &:hover {
      background: ${theme.colors.hoverOrange}
    }
  }
  
  .ant-checkbox-input, .ant-form-item-explain.ant-form-item-explain-success {
    display: none !important;
  }
  
  .ant-checkbox {
    width: 24px;
    height: 24px;
    top: 0;
    margin-top: 1px;
  }
 
  .ant-checkbox-inner, .ant-checkbox-checked {
    position: relative;
    border: 1px solid #7E8593 !important;
    top: 0;
    width: 24px;
    height: 24px;
    display: inline-flex;
    background: #fff;
    box-sizing: border-box;
    border-radius: 0;
    transition: none;
    
    &:hover {
      border: 1px solid #7E8593;
    } 
  }
  
  .ant-checkbox-checked {
    //margin-top: 5px;
    border: 1px solid ${theme.colors.orange} !important;
       
    &:after {
      display: none;
    }
  }
  
  .ant-checkbox-checked .ant-checkbox-inner {
    content: '';
    z-index: 999;
    background: ${theme.colors.orange};
    border: 2px solid ${theme.colors.white} !important;
    width: 22px;
    height: 22px;
    top: 0px;
    left: 0px;
    border-radius: 0;
  }
  
  .ant-checkbox-checked .ant-checkbox-inner::after {
    display: none;
  }
  
  .ant-checkbox + span {
    padding-right: 0px;
    padding-left: 0px;
  }
  
  .ant-select-arrow {
    //z-index: 2;
    //color: black;
    //width: 40px;
    //margin-top: 0px;
  }
  
  .ant-checkbox-wrapper {
    align-items: center;
  }
  
  .input .ant-form-item-explain-error, .checkbox .ant-form-item-explain-error {
    display: none;
  }
  
  .ant-form-item-explain.ant-form-item-explain-error {
    transition: none;
  }
  
  .white-border {
    border: 1px solid theme.colors.white !important;
  }
  
  .checkbox .ant-form-item-explain-error {
    width: 24px;
    height: 24px;
    left: 0px;
    top: 7px;
  }
  
  .checkbox .ant-form-item-explain-error div {
    display: none;
  }
  
  .ant-form-item-has-error .ant-input {
    border-color: #ff4d4f !important;
  }
  
  .ant-form-item-has-error {
    .ant-checkbox-inner {
      border-color: #ff4d4f !important;
    }
  }
  
  .ant-form-item-has-error .ant-select {
    .ant-select-selection-placeholder {
      color: #ff4d4f !important;
    }
  }
  
  .ant-form-item-explain, .ant-form-item-extra {
    min-height: 0 !important;
  }
  
  .ant-form-item {
    //margin-bottom: 38px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  
  .ant-checkbox-wrapper span p {
    margin-top: 3px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 24px;
    color: #7E8593;
    text-align: left;
    letter-spacing: -0.2px;
    margin-left: 13px;
  }

  .checkbox.profile-privacy-policy {
    margin-bottom: 0!important;
  }
  


  .lk__parametr-range-picker-dropdown {
    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner.lk__half-disabled-date-right, 
    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner.lk__half-disabled-date-left, 
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner.lk__half-disabled-date-right, 
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner.lk__half-disabled-date-left {
      background: linear-gradient(120deg, #ef735712 50%, #ef735712 50%);
    } 
    
    .ant-picker-cell-inner.lk__half-disabled-date-left {
      background: linear-gradient(120deg, #ef735712 50%, transparent 50%);
      box-shadow: -14px 0 #ef735712;
    }

    .ant-picker-cell-inner.lk__half-disabled-date-right {
      background: linear-gradient(120deg, transparent 50%, #ef735712 50%);
      box-shadow: 14px 0 #ef735712;
    }

    .ant-picker-cell-inner.lk__half-disabled-date-full {
      background:  #ef735712;
      box-shadow: 14px 0 #ef735712, -14px 0 #ef735712;
    }

    .ant-picker-cell-disabled::before {
      background:  #ef735712;
    }

    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
      background: #ef735712;
    }

    .ant-picker-header-view button:hover {
      color: #ef6957;
    }
    
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
      background: ${theme.colors.orange};
    }

    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, 
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
    .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
      background: ${theme.colors.translucentOrange};
    }

    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
      border-color: ${theme.colors.translucentOrange};
    }

    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
    .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
    .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
      background: ${theme.colors.hoverPickerOrange};
    }
  }
  
  @media (min-width: 990px) {
    textarea.ant-input {
      padding-top: 20px;
      padding-bottom: 10px;
      height: auto !important;
    }

    .ant-form-item-with-help.checkbox:not(.profile-privacy-policy) {
      margin-bottom: 24px !important;
    }
  }
  
  @media (max-width: 990px) {

    .ant-form-item-label {
      display: block;
      line-height: 18px;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content,
    .ant-form-item-label label {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 4px!important;
      
      display: block;
    }

    .input-block {
      margin-bottom: 15px;
      margin-top: 14px;
    }

    .ant-input, .ant-select-selector {
      margin-top: 0!important;
    }
    
    .ant-input {
      margin-top: 0!important;
    }
    
    .ant-form-item {
      margin-bottom: 16px;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content,
    .ant-form-item-label label {
      font-size: 12px !important;
    }
    
    input, textarea, .ant-select-selector {
      font-size: 12px !important;
      line-height: 14px !important;
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
    
    input:not(.ant-radio-input), .ant-select-selector {
      height: 40px !important;
    }


    .ant-select-arrow {
      //top: 38%;
    }
    
    .ant-select-selection-placeholder, .ant-select-selection-item {
      padding-top: 5px !important;
      font-size: 12px !important;
      //font-weight: 100 !important;
    }
    
    .ant-select-selection-placeholder {
      color: ${theme.colors.darkGray} !important;
    }

    .ant-checkbox {
      width: 16px;
      height: 16px;
    }
    
    .ant-checkbox-inner, .ant-checkbox-checked {
      width: 16px;
      height: 16px;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      width: 14px;
      height: 14px;
    }
    
    .checkbox .ant-form-item-explain-error {
      width: 16px !important;
      height: 16px !important;
      top: 11px;
    }
    
    .ant-checkbox-wrapper span p {
      font-size: 10px !important;
      line-height: 15px !important;
    }
    
  }

  @media (max-width: 575px) {

    .ant-picker-decade-panel, .ant-picker-year-panel, .ant-picker-quarter-panel, 
    .ant-picker-month-panel, .ant-picker-week-panel, .ant-picker-date-panel, .ant-picker-time-panel {
      width: 200px;
    }
    .ant-picker-decade-panel .ant-picker-content, .ant-picker-year-panel .ant-picker-content, 
    .ant-picker-quarter-panel .ant-picker-content, .ant-picker-month-panel .ant-picker-content {
      height: 190px;
    }
  }

  @media (max-width: 420px) {
    .ant-picker-decade-panel, .ant-picker-year-panel, .ant-picker-quarter-panel,
    .ant-picker-month-panel, .ant-picker-week-panel, .ant-picker-date-panel, .ant-picker-time-panel {
      width: 230px;
    }
    .ant-picker-decade-panel .ant-picker-content, .ant-picker-year-panel .ant-picker-content,
    .ant-picker-quarter-panel .ant-picker-content, .ant-picker-month-panel .ant-picker-content {
      height: 170px;
    }
    
    .ant-picker-range-wrapper.ant-picker-month-range-wrapper {
      width: auto;
      transform: translateX(0px);
    }
    .ant-picker-panel-container .ant-picker-panels { 
      display: flex;
      flex-direction: column;
    }
    .ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
      transform: rotate( -45deg ) translateY(-9px);
    }
  }
  
  
  .btn:hover {
    color: ${theme.colors.white};
  }

  .accept-policy {
    .a2 {
      font-size: 14px;
      color: #7E8593;
      font-weight: normal;
      text-decoration: underline;
      
      &:hover {
        text-decoration: none;
        color: #4E535B;
        border-color: #ffffff00;
      }
      
      @media (max-width: 990px) {
        font-size: 10px;
      }
    }
  }
  
  
  
  
  //Custom Tooltip


  .ant-tooltip {
    max-width: 473px;
    white-space: pre-wrap;
  }

  .ant-tooltip.lk__tariff-tooltip {
    max-width: 250px;
  }

  .ant-tooltip-arrow-content {
    background: ${theme.colors.white};
    --antd-arrow-background-color: ${theme.colors.white};
  }

  .vacancy-hidden-price-tooltip .ant-tooltip-inner,
  .checkbox-unsubscribe-switch .ant-tooltip-inner,
  .vacancy-posted-again-title .ant-tooltip-inner,
  .lk__salary-tooltip .ant-tooltip-inner,
  .lk__tariff-tooltip .ant-tooltip-inner,
  .lk__divider-tooltip .ant-tooltip-inner {
    background: white;
    color: ${theme.colors.black};
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    z-index: 2;
    width: auto;
    max-width: 572px;
    padding: 16px;
  }

  .lk__tariff-tooltip .ant-tooltip-inner,
  .lk__divider-tooltip .ant-tooltip-inner,
  .checkbox-unsubscribe-switch .ant-tooltip-inner {
    font-size: 14px;
  }

  .ant-tooltip-inner .tooltip-text-wrapper > p {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    &.tooltip-list-block > p {
      margin-bottom: 0;
    }
  }

  @media (max-width: 990px) {
    .vacancy-hidden-price-tooltip .ant-tooltip-inner,
    .vacancy-posted-again-title .ant-tooltip-inner,
    .lk__salary-tooltip .ant-tooltip-inner {
      max-width: 400px;
      font-size: 12px;
      line-height: 18px;
      padding: 12px;
    }

    .ant-tooltip-inner .tooltip-text-wrapper > p {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 575px) {
    .vacancy-hidden-price-tooltip .ant-tooltip-inner,
    .vacancy-posted-again-title .ant-tooltip-inner,
    .lk__salary-tooltip .ant-tooltip-inner {
      max-width: 300px;
      padding: 8px;
    }

    .ant-tooltip-inner .tooltip-text-wrapper > p {
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const Container = styled.div`
  max-width: 1500px;
  width: 100%;
  margin: auto;
  padding: 0 30px;
  
  &.centered-by-horizontal {
    margin: 0 auto;
    flex: 1;
  }

  @media (max-width: 1600px) {
    padding:0 70px;
  }
  
  @media (max-width: 1140px) {
    padding:0 20px;
  }

  @media (max-width: 760px) {
    &.no-padding {
      padding: 0;
    }
  }
`;


export const BreadcrumbsContainer = styled.ul`

  &.breadcrumbs {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    list-style-type: none;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #8A8A89;
    padding: 0;
    margin: 0;
    padding-top: 79px
  }

  &.breadcrumbs a {
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit
  }

  .breadcrumbs__item {
    white-space: nowrap
  }

  .breadcrumbs__item,.breadcrumbs__item:after {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
  }

  .breadcrumbs__item:after {
    content: "—";
    width: auto;
    height: auto;
    margin: 0 9px;
    -ms-flex-pack: center;
    justify-content: center;
    color: #8A8A89
  }

  .breadcrumbs__item:last-child:after {
    display: none
  }

  .breadcrumbs__item span {
    color: #8A8A89
  }

  .breadcrumbs__link {
    color: #9F9F9F
  }

  .breadcrumbs__link:hover {
    text-decoration: underline
  }

  @media (max-width: 990px) {
    &.breadcrumbs {
      font-size:14px;
      line-height: 17px;
      padding-top: 23px
    }
  }
`;


export const ButtonOrange = styled.button`
  transition: ${theme.animation.transition};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  background: ${theme.colors.orange};
  max-width: 351px;
  width: 100%;
  border: none;
  color: ${theme.colors.lightGray};
  margin-top: ${ props => props.withoutMargin ? '0' : '56px'};
  padding: 10px 20px 9px;
  min-width: 151px;
  cursor: pointer;
  
  &:hover {
    background: ${theme.colors.hoverOrange}
  }
`;

export const CookiePolicyWrapper = styled.div`
  z-index: 10;
  width: 100%;
  position: fixed; 
  bottom: 0px;
  right: 0px;
  background: white;
  box-shadow: 0 1px 138px rgb(0 0 0 / 3%), 0 -5.54443px 9.17714px rgb(0 0 0 / 7%);
  
  @media (max-width: 760px) {
    max-width: 100%;
  }
`;

export const CookiePolicyContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  
  div {
    overflow: auto;
    max-height: 35vh;
  }
  
  a {
    margin-left: 20px;
  }

  @media (max-width: 760px) {
    flex-direction: column;
    
    a {
      margin-left: 0px;
      margin-top: 20px;
    }
  }
`;

export const ContainerForEmbeddingText = styled.div`

  margin-top: 48px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  
  .payable-service-description {
    white-space: break-spaces;
  }

  :first-child {
    margin-top: 0;
  }
  
  article {
    > *:first-child {
      margin-top: 0;
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    margin-top: 25px
  }

  p:first-child {
    margin-top: 0;
  }

  h2 {
    margin-top: 64px;
    margin-bottom: 24px
  }
  
  h3, h4, h5 {
    margin-top: 25px
  }

  ul {
    padding-left: 21px
  }

  li {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 12px;
    color: #323232
  }

  ul li::marker {
    color: #8A8A89
  }

  ul li:last-of-type {
    margin-bottom: 0
  }

  @media (max-width: 1440px) {
    h2 {
      margin-top: 40px;
      margin-bottom: 20px
    }

    p,
    ul li {
      font-size: 20px;
      line-height: 28px
    }

    p, h3, h4, h5  {
      margin-top: 15px
    }
  }

  @media (max-width: 1140px) {
    display: block;
  }

  @media (max-width: 990px) {
    margin-top: 40px;

    p,
    ul li {
      font-size: 14px;
      line-height: 20px
    }

    p, h3, h4, h5  {
      margin-top: 8px
    }

    h2 {
      margin-top: 32px;
      margin-bottom: 12px
    }

    ul li {
      margin-bottom: 8px
    }
  }

  @media (max-width: 575px) {
    ul {
      padding-left: 16px
    }

    ul li {
      padding-left: 4px
    }
  }

  /* -----------    Страница вакансии    ----------- */

  &.vacancy__content .text-vacancy-outdated {
    color: #EF6957;
  }

  &.vacancy__content .vacancy-removed-from-published-text {
    color: #EF6957;
    margin-top: 115px;
    margin-bottom: 139px; }
  @media (max-width: 550px) {
    &.vacancy__content .vacancy-removed-from-published-text {
      margin-top: 85px;
      margin-bottom: 85px; }}
`;